<template>
  <div>
    <!-- <Cashier /> -->
    <b-container fluid>
      <b-row>
        <b-col class="px-0 border-right-light border-right-line bg-gray-2" cols="12" md="6" lg="6"
          style="position: relative; height: 100vh">
          <!-- <b-button @click="finishTutorial">
            TEST
          </b-button> -->
          <Search :is_add="is_add" @getIsAdd="getIsAdd" />
          <LastProductSelected :getProfiles="getProfiles" />
        </b-col>
        <CartProduct :canCheckout="canCheckout" :mustOpenPettyCash="mustOpenPettyCash" :isLoading="isLoading" />
      </b-row>
    </b-container>
    <app-tour :nameTour="'cashierTour1'" :steps="steps" />
    <app-tour :nameTour="'cashierTour3'" :steps="steps3" />
    <app-tour :nameTour="'cashierTour4'" :steps="steps4" />
    <app-tour :nameTour="'cashierTour5'" :steps="steps5" />
    <ModalAddtionalInputDiscount />
    <ModalTutorial />
    <!-- <ModalAddProductToCart /> -->
  </div>
</template>

<script>
import { BContainer, BRow, BCol } from "bootstrap-vue";
import {
  checkPermission,
  successNotification,
  errorNotification,
} from "@/auth/utils";
// import AppTour from '@core/components/app-tour/AppTour.vue'
// import Search from '@/components/Cashier/Header.vue'

export default {
  title() {
    return "Kasir";
  },
  components: {
    Search: () => import("@/components/Cashier/Header.vue"),
    BContainer,
    BRow,
    BCol,
    LastProductSelected: () =>
      import("@/components/Cashier/LastProductSelected.vue"),
    CartProduct: () => import("@/components/Cashier/Cart/CartProduct.vue"),
    // ModalAddProductToCart,
    ModalAddtionalInputDiscount: () =>
      import("@/components/Cashier/Modal/AdditionalInputDiscount.vue"),
    AppTour: () => import("@core/components/app-tour/AppTour.vue"),
    ModalTutorial: () => import("@/components/ModalTutorial.vue"),
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    };
  },
  data() {
    return {
      data: Object,
      is_add: false,
      userData: JSON.parse(localStorage.getItem("userData")),
      canCheckout: null,
      mustOpenPettyCash: null,
      isLoading: false,
      steps: [
        {
          target: "#tour-1",
          content: `
                    <div class="text-white size16 mb-1">Mulai Transaksi dari Menu “Transaksi”</div>
                    <div class="text-white2 size14" style="line-height: 24px;">Anda dapat langsung melakukan transaksi  melalui menu “Transaksi” di sini.</div`,
          params: {
            placement: "right-start", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          },
          buttonNext: "Oke, mengerti",
          isHideButton: false,
        },
        {
          target: "#tour-search",
          content: `
                    <div class="text-white size16 mb-1">Cari Produk</div>
                    <div class="text-white2 size14" style="line-height: 24px;">Anda dapat menemukan produk yang dicari dengan mengetik nama produk di bagian ini.</div`,
          buttonNext: "Selanjutnya",
          isHideButton: false,
        },
        {
          target: "#tour-add-cart",
          content: `
                    <div class="text-white size16 mb-1">Tambahkan ke Keranjang</div>
                    <div class="text-white2 size14" style="line-height: 24px;">Tekan tombol “Tambahkan” di atas, agar produk ini dimasukkan ke dalam keranjang.</div>
                  `,
          hint: "Klik tombol “Tambahkan” untuk melanjutkan tutorial ini.",
          buttonNext: "Saya mengerti",
          isHideButton: false,
        },
      ],
      steps3: [
        {
          target: "#tour-select-customer",
          content: `
                    <div class="text-white size16 mb-1">Masukkan Pelanggan</div>
                    <div class="text-white2 size14" style="line-height: 24px;">Klik di “Masukkan Pelanggan” untuk masuk ke menu mencantumkan nama pelanggan.</div`,
          params: {
            placement: "bottom", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          },
          hint: "Klik tombol “Masukkan pelanggan” untuk melanjutkan tutorial.",
          buttonNext: "Saya mengerti",
          isHideButton: false,
        },
      ],
      steps4: [
        {
          target: "#tour-add-customer",
          content: `
                    <div class="text-white size16 mb-1">Tambah Pelanggan Baru</div>
                    <div class="text-white2 size14" style="line-height: 24px;">Kamu bisa menginputkan nama pelanggan <br> yang bertransaksi dengan kamu.</div`,
          params: {
            placement: "bottom-start", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          },
          buttonNext: "Saya mengerti",
          isHideButton: false,
          class: "custom__tour--add-customer",
        },
        {
          target: "#tour-select-customer-0",
          content: `
                    <div class="text-white size16 mb-1">Pilih Nama Pelanggan</div>
                    <div class="text-white2 size14" style="line-height: 24px;">Pilih nama pelanggan atau pilih “Konsumen” jika ingin dikenali sebagai konsumen biasa.</div`,
          params: {
            placement: "bottom-start", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          },
          hint: "Klik nama untuk mencoba memasukkan nama pelanggan.",
          buttonNext: "Saya mengerti",
          isHideButton: false,
          class: "custom__tour--choose-customer",
        },
      ],
      steps5: [
        {
          target: "#tour-delete-cart-0",
          content: `
                    <div class="text-white size16 mb-1">Hapus Produk dari Keranjang</div>
                    <div class="text-white2 size14" style="line-height: 24px;">Tekan tombol “X” untuk menghapus 1 produk yang ada di dalam keranjang.</div`,
          params: {
            placement: "bottom-start", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          },
          buttonNext: "Saya mengerti",
          isHideButton: false,
        },
        {
          target: "#tour-process-transaction",
          content: `
                    <div class="text-white size16 mb-1">Proses Transaksi ke Pembayaran</div>
                    <div class="text-white2 size14" style="line-height: 24px;">Jika keranjang sudah sesuai kamu bisa melanjutkan ke proses pembayaran.</div`,
          params: {
            placement: "top", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          },
          hint: "Tekan tombol Hijau di bawah ini untuk memproses transaksi ke pembayaran.",
          buttonNext: "Saya mengerti",
          isHideButton: false,
        },
      ],
    };
  },
  watch: {
    "$store.state.cashier.userData": {
      handler(value) {
        this.userData = value;
      },
      deep: true,
    },
    "$route.query.uuid": {
      handler(val) {
        if (!val) {
          localStorage.removeItem("customer");
        }
      },
      deep: true,
    },
  },
  created() {
    // if (checkPermission('kasir')) {
    this.getProfiles();
    this.getActiveCart();
    this.userData = JSON.parse(localStorage.getItem("userData"));
    // } else {
    //   this.$router.push('/welcome')
    // }
    localStorage.setItem("customer_uuid", "");
    if (!this.userData.finish_tutorial) {
      this.$bvModal.show("modal-tutorial");
    }
  },
  mounted() {
    if (Object.keys(this.$route.query).length === 0) {
      localStorage.removeItem("customer_info");
      this.$store.commit("customer/setCustomer", {});
      this.$store.commit("cart/setResultCartActive", {});
    }
    localStorage.removeItem("is_piutang");
    localStorage.removeItem("customer_supplier_uuid");
    localStorage.removeItem("delivery_pickup");
  },
  methods: {
    finishTutorial() {
      this.$store
        .dispatch("tutorial/postData")
        .then((result) => {
          const userData = JSON.stringify(result.data.data);
          localStorage.setItem("userData", userData);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
        });
    },
    tourStart() {
      this.$tours.cashierTour1.start();
    },
    getIsAdd(value) {
      this.is_add = value;
    },
    getProfiles() {
      this.isLoading = true;
      this.$store
        .dispatch("profile/getProfile")
        .then((response) => {
          this.canCheckout = response.data.data.can_checkout;
          this.mustOpenPettyCash = response.data.data.must_open_petty_cash;
          this.isLoading = false;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
          this.isLoading = false;
        });
    },
    async getActiveCart() {
      await this.$store
        .dispatch("cart/getCart", {
          uuid: this.$route.query.uuid || "",
          params: "",
        })
        .then((result) => {
          const item = result.data.data;
          console.log(item, "data item");
          // const customer = JSON.stringify(result.data.data)
          this.$store.commit(
            "cart/SET_TOTAL_ADDITIONAL_COST",
            item.additional_fee_total
          );
          this.$store.commit("cart/setDiscountCart", item.discount_value);
          this.$store.commit("cart/setDiscountType", item.discount_type);
          this.$store.commit("cart/setDiscountTotal", item.discount_total);
          this.$store.commit("cart/setSubtotalCart", item.subtotal);
          this.$store.commit("cart/setTotalPrice", item.total);
          this.$store.commit("cart/setTotalBuy", item.items.length);
          this.$store.commit("cart/setCartUuid", item.uuid);
          this.$store.commit("cart/setResultCartActive", item);
          this.$store.commit("cashier/setProduct", item.items.length);
          this.$store.commit(
            "cart/SET_FORM_ADDITIONAL_COST",
            localStorage.getItem("additional_cost") || null
          );
          if (this.$route.query.uuid) {
            localStorage.setItem("customer_info", JSON.stringify(item));
            this.$store.commit("customer/setCustomer", item);
          }
          localStorage.setItem("cartUuid", item.uuid);
          localStorage.setItem("cash_total_payment", item.total);
          localStorage.setItem("customer", JSON.stringify(item.customer));
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@core/scss/vue/libs/tour.scss";

.text-white2 {
  color: #e2e8f0;
}

.v-tour {
  .v-step {
    background-color: #334155;

    .v-step__arrow {
      border-color: #334155;
    }
  }
}

.search__box {
  display: flex;
  align-content: center;
  width: 100%;
  height: 100%;
  padding: 1.1rem 2rem;
  background: #f6f7fa;

  .search__icon {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .search__text {
    width: 100%;

    input {
      background: transparent;
      border: none;
      height: 50px;

      &.focus-0:focus {
        box-shadow: none !important;
      }
    }
  }
}

.btn-delete {
  &:hover {
    box-shadow: none !important;
  }
}

/* =============== CART PRODUCT =============== */
.cart__product--container {
  .floating__button {
    position: sticky;
    width: 100%;
    bottom: 0;
  }

  .scroll__cart {
    overflow-y: scroll;
    height: 73vh;
  }

  .card {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
    border-radius: 12px;

    .rounded__frame {
      display: flex;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: #d5f1ee;
      margin-right: 1rem;
    }

    .rounded__frame.print__logo {
      background: #e4f8f6;
    }
  }

  .card.card__discount {
    background: #f1f9f8;
    border-radius: 16px 16px 0px 0px;
    box-shadow: none;
  }

  .card.card__click:hover {
    cursor: pointer;
  }

  .b-avatar.badge-light-warning.custom-avatar {
    background-color: #fef9f5;
    color: #e79242;
  }

  .media-list {
    .media {
      position: relative;

      .media-aside-right {
        margin-left: 0px !important;

        >div {
          width: 134px;
        }
      }

      .media-body {
        display: flex;
        flex-direction: column;
        margin-right: 5px;
        height: 18vh;
        justify-content: space-between;
      }

      &:not(:last-child)::after {
        content: "";
        width: 100%;
        position: absolute;
        bottom: -3vh;
        border-bottom: 1px solid #e4e5ec;
      }

      .floating__container {
        position: relative;

        .floating__close--button {
          position: absolute;
          top: 6px;
          width: 24px;
          height: 24px;
          right: 6px;
          background: #fff;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .media__image {
        width: 100%;
        height: 85px;
        object-fit: cover;
        border-radius: 8px;
      }

      .spinner__button {
        width: 100%;
        display: flex;
        padding: 0.5rem;
        background: $light--1;
        border-radius: 8px;

        #input-wrap {
          margin: 0px;
          padding: 0px;
        }

        .value-button {
          display: flex;
          justify-content: center;
          align-items: center;
          // border: 1px solid #ddd;
          margin: 0px;
          width: 65px;
          text-align: center;
          vertical-align: middle;
          background: #fff;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
          border-radius: 6px;
        }

        .value-button:hover {
          cursor: pointer;
        }

        input.qty {
          text-align: center;
          border: none;
          margin: 0px;
          width: 100%;
          background: transparent;
          height: 32px;

          &:focus {
            border: none;
            outline: none;
          }
        }

        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
  }
}
</style>
